html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  font-family: 'proxima-nova', helvetica neue, helvetica, arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #505658;
  text-rendering: optimizeSpeed;
}
p {
  margin-top: 0;
  margin-bottom: 30px;
}
::-moz-selection {
  background-color: #66d9ff;
  color: #fff;
}
::selection {
  background-color: #66d9ff;
  color: #fff;
}
a {
  color: #00b0eb;
  text-decoration: none;
  cursor: pointer;
}
a:focus {
  outline: none;
}
a:hover {
  color: #008dbc;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.hide {
  display: none;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
img,
iframe,
video {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.25;
  font-family: 'aktiv-grotesk', helvetica neue, helvetica, arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
h6 {
  text-transform: uppercase;
  color: #848d90;
}
abbr {
  color: #848d90;
  border-bottom: 1px dotted #cdd3d5;
  cursor: help;
}
small,
sup,
sub {
  font-size: 12px;
}
mark {
  padding: 3px 5px;
  background: #ffffb3;
}
blockquote {
  margin: 30px 0;
  padding-left: 30px;
  position: relative;
  color: #848d90;
  font-family: 'proxima-nova', helvetica neue, helvetica, arial, sans-serif;
  font-size: inherit;
}
blockquote:before,
blockquote:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #fec3d3;
}
blockquote p:only-child,
blockquote p:last-child {
  margin: 0;
}
blockquote footer {
  margin-top: 15px;
  font-size: 16px;
}
blockquote footer:before {
  content: '\2014 \00A0';
}
.blockquote-centered {
  text-align: center;
  padding: 60px 0;
}
.blockquote-centered:before,
.blockquote-centered:after {
  left: 40%;
  width: 20%;
  height: 3px;
}
.blockquote-centered:after {
  top: auto;
  bottom: 0;
}
.blockquote-medium {
  font-size: 22px;
}
.blockquote-large {
  font-size: 36px;
  line-height: 1.3;
}
@media screen and (max-width: 899px) {
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  .blockquote-large {
    font-size: 32px;
  }
}
@media screen and (min-width: 900px) {
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}
ul,
ol {
  margin: 0 0 30px 0;
  padding-left: 30px;
}
ul li > ul,
ol li > ul,
ul li > ol,
ol li > ol {
  margin-top: 30px;
}
ul li ul li,
ol li ul li {
  list-style: circle;
}
ul li li ul li,
ol li li ul li {
  list-style: square;
}
ul li {
  list-style: disc;
}
.list-unstyled {
  padding-left: 0;
}
.list-unstyled li {
  list-style: none;
}
.list-inline li {
  display: inline-block;
}
dl dt {
  font-weight: 500;
  color: #000;
}
dl dd {
  margin: 0;
}
.container {
  margin: 0 auto;
}
.grid-flex-container {
  margin: -30px 0   30px -30px;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
[class^="grid-flex-cell"],
[class*=" grid-flex-cell"] {
  padding: 30px 0 0 30px;
}
@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
  [class^="grid-flex-cell"],
  [class*=" grid-flex-cell"] {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 601px) {
  [class^="grid-flex-cell"],
  [class*=" grid-flex-cell"] {
    flex: 1;
  }
  .grid-flex-cell-1of2,
  .grid-flex-cell-1of3,
  .grid-flex-cell-1of4 {
    flex: none;
  }
  .grid-flex-cell-1of2 {
    width: 50%;
  }
  .grid-flex-cell-1of3 {
    width: 33.333333333333336%;
  }
  .grid-flex-cell-1of4 {
    width: 25%;
  }
  .grid-flex-cell-centered {
    margin-left: auto;
    margin-right: auto;
  }
  .grid-flex-cell-centered :last-child {
    float: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 900px) {
  .container {
    width: 85%;
  }
}
@media screen and (min-width: 901px) {
  .container {
    width: 70%;
  }
}
select,
.form-input,
.form-checkbox,
.form-radio {
  margin-bottom: 15px;
}
legend,
label {
  font-weight: 800;
}
legend {
  padding: 0 15px;
  font-size: 22px;
  color: #000;
}
fieldset {
  border: 1px solid #cdd3d5;
  border-radius: 3px;
  padding: 30px;
  margin: 0 0 30px 0;
}
label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}
select,
.form-input,
.form-radio,
.form-checkbox {
  outline: 0;
}
.form-inline {
  overflow: hidden;
}
.form-input {
  display: block;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #cdd3d5;
  -webkit-appearance: none;
}
.form-input:focus {
  transition: all 0.2s ease-in;
  border: 1px solid #66d9ff;
  background-color: #f7fdff;
}
.form-input:disabled {
  background-color: #fbfbfb;
  cursor: not-allowed;
}
.form-input:required:focus {
  border: 1px solid #fec57c;
  background-color: #fff9f2;
}
.form-input.input-invalid {
  border: 1px solid #ffc7d8;
  color: #ff739d;
}
.form-input.input-invalid:focus {
  border: 1px solid #e60045;
  background-color: #fff4f7;
}
.form-input.input-valid {
  border: 1px solid #c6ebd4;
  color: #8fd6a9;
}
.form-input.input-valid:focus {
  border: 1px solid #44bb70;
  background-color: #f4fbf6;
}
textarea {
  resize: none;
  min-height: 90px;
}
input[type=file],
label.radio,
label.checkbox,
select {
  cursor: pointer;
}
input[type=file] {
  padding: 10px;
  background-color: #f8f9f9;
  font-size: 12px;
  color: #cdd3d5;
}
select {
  border: 1px solid #cdd3d5;
  height: 40px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
select:focus {
  border: 1px solid #66d9ff;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.input-radio,
.input-checkbox {
  margin: 5px 0;
  font-size: 16px;
  font-weight: normal;
}
.input-radio.disabled,
.input-checkbox.disabled {
  color: #cdd3d5;
  cursor: not-allowed;
}
.input-radio input[type=checkbox],
.input-checkbox input[type=checkbox],
.input-radio input[type=radio],
.input-checkbox input[type=radio] {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .form-inline.form-no-labels .button {
    margin-top: 0;
  }
  .form-inline .form-element {
    float: left;
    width: 50%;
  }
  .form-inline .button {
    margin-top: 22.5px;
  }
  .form-inline .form-element:not(:first-of-type) {
    padding-left: 5px;
  }
  .form-inline .form-input,
  .form-inline .input-checkbox,
  .form-inline .input-radio,
  .form-inline select {
    margin: 0;
  }
}
.button {
  display: inline-block;
  border: none;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  cursor: pointer;
  background-color: #00b0eb;
  transition-delay: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-property: background, border-color;
  user-select: none;
  appearance: none;
}
.button:not([class*='button-outlined']) {
  padding-top: 10px;
  padding-bottom: 10px;
}
.button:hover {
  background-color: #009ed3;
}
.button:disabled {
  background-color: #66d9ff;
  cursor: not-allowed;
}
.button.button-disabled {
  cursor: not-allowed;
}
.button:focus {
  outline: none;
}
.button,
.button:hover {
  color: #fff;
}
.button-small {
  padding: 3px 10px;
  font-size: 12px;
}
.button-large {
  padding: 15px 30px;
  font-size: 22px;
}
.button-unstyled,
.button-unstyled:hover {
  background-color: transparent;
}
.button-unstyled {
  padding: 0;
  color: #00b0eb;
}
.button-unstyled:hover {
  color: #008dbc;
}
.button-neutral {
  background-color: #e9ecec;
}
.button-neutral:hover {
  background-color: #b6bfc2;
}
.button-neutral:disabled,
.button-neutral .button-disabled {
  background-color: #e9ecec;
  color: #cdd3d5;
}
.button-neutral:disabled:hover,
.button-neutral .button-disabled:hover {
  color: #cdd3d5;
}
.button-neutral,
.button-neutral:hover {
  color: #262a2c;
}
.button-approve {
  background-color: #44bb70;
}
.button-approve:hover {
  background-color: #369659;
}
.button-approve:disabled,
.button-approve .button-disabled {
  background-color: #c6ebd4;
}
.button-warn {
  background-color: #e60045;
}
.button-warn:hover {
  background-color: #b80037;
}
.button-warn:disabled,
.button-warn.button-disabled {
  background-color: #ffc7d8;
}
.button-caution {
  background-color: transparent;
  color: #e60045;
  text-decoration: underline;
}
.button-caution:hover {
  background-color: transparent;
  color: #b80037;
}
.button-caution:disabled,
.button-caution .button-disabled {
  color: #cdd3d5;
}
*[class*='button-outlined'] {
  border-width: 2px;
  border-style: solid;
  padding-top: 8px;
  padding-bottom: 8px;
}
*[class*='button-outlined'],
*[class*='button-outlined']:hover,
*[class*='button-outlined']:disabled {
  background-color: transparent;
}
.button-outlined {
  border-color: #00b0eb;
  color: #00b0eb;
}
.button-outlined:hover {
  border-color: #008dbc;
  color: #008dbc;
}
.button-outlined:disabled {
  border-color: #66d9ff;
  color: #66d9ff;
}
.button-outlined-neutral {
  border-color: #cdd3d5;
  color: #848d90;
}
.button-outlined-neutral:hover {
  border-color: #848d90;
  color: #505658;
}
.button-outlined-neutral:disabled,
.button-outlined-neutral.button-disabled {
  color: #d7dcde;
  border-color: #e9ecec;
}
.button-outlined-neutral:disabled:hover,
.button-outlined-neutral.button-disabled:hover {
  color: #d7dcde;
}
.button-outlined-approve {
  border-color: #44bb70;
  color: #44bb70;
}
.button-outlined-approve:hover {
  border-color: #369659;
  color: #369659;
}
.button-outlined-approve:disabled {
  border-color: #c6ebd4;
  color: #c6ebd4;
}
.button-outlined-warn {
  border-color: #e60045;
  color: #e60045;
}
.button-outlined-warn:hover {
  border-color: #b80037;
  color: #b80037;
}
.button-outlined-warn:disabled {
  border-color: #ffc7d8;
  color: #ffc7d8;
}
.button-group {
  font-size: 0;
}
.button-group .button {
  font-size: 16px;
  border-radius: 0;
  margin-left: -1px;
  border: 1px solid #008dbc;
}
.button-group .button.button-large {
  font-size: 22px;
}
.button-group .button.button-small {
  font-size: 12px;
}
.button-group .button:first-child,
.button-group .button:first-child:only-child {
  margin: 0;
}
.button-group .button:first-child:only-child {
  border-radius: 3px;
}
.button-group .button:first-child {
  border-radius: 3px 0 0 3px;
}
.button-group .button:last-child {
  border-radius: 0 3px 3px 0;
}
.button-group .button-neutral,
.button-group .button-outlined,
.button-group .button-outlined-warn,
.button-group .button-outlined-approve {
  border: 1px solid #cdd3d5;
}
@media screen and (max-width: 480px) {
  .button-group .button {
    border-radius: none;
    margin: 0;
    width: 100%;
  }
  .button-group .button:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  .button-group .button:last-of-type {
    border-radius: 0 0 3px 3px;
  }
  .button-group .button:not(:last-of-type) {
    border-bottom: none;
  }
}
code {
  font-family: Menlo, Monaco, Consolas, monospace;
  font-size: 12px;
  padding: 3px 5px;
}
pre {
  display: block;
  padding: 15px;
  margin-bottom: 30px;
  max-width: 100%;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
}
pre code {
  padding: 0;
  white-space: pre-wrap;
}
code,
pre {
  border-radius: 3px;
}
.code-light {
  color: #000;
  background: #f6f7f8;
}
.code-dark {
  color: #52a4bf;
  background: #003647;
}
.dropdown-standalone {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
}
.dropdown-standalone:hover {
  border-radius: 3px 3px 0 0;
}
.dropdown-standalone:hover .dropdown {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 0 0 3px 3px;
}
.dropdown-standalone:hover .dropdown a {
  padding: 10px;
  display: block;
}
.dropdown-standalone .dropdown {
  display: none;
}
.dropdown-standalone .icon-arrow-down {
  position: relative;
  top: -2px;
  margin-left: 5px;
}
.dropdown-outlined,
.dropdown-outlined .dropdown {
  border: 1px solid #cdd3d5;
}
.dropdown-standalone.dropdown-outlined .dropdown {
  width: 101%;
  left: -1px;
}
.dropdown-outlined .dropdown {
  background-color: #fff;
}
.dropdown-outlined .dropdown li:not(:last-child) {
  border-bottom: 1px solid #cdd3d5;
}
.dropdown-coloured,
.dropdown-coloured .dropdown {
  background-color: #00b0eb;
}
.dropdown-coloured .button-unstyled,
.dropdown-coloured .dropdown a {
  color: #fff;
}
.dropdown-coloured .icon-arrow-down {
  border-top-color: #fff;
}
.dropdown-coloured .dropdown li:not(:last-child) {
  border-bottom: 1px solid #009ed3;
}
.dropdown-coloured .dropdown a:hover {
  color: #e5f9ff;
}
.dropdown-neutral,
.dropdown-neutral .dropdown {
  background-color: #e9ecec;
}
.dropdown-neutral a {
  color: #505658;
}
.dropdown-neutral a:hover,
.dropdown-neutral .button-unstyled:hover {
  color: #262a2c;
}
.dropdown-neutral .icon-arrow-down {
  border-top-color: #505658;
}
.dropdown-neutral .dropdown li:not(:last-child) {
  border-bottom: 1px solid #cdd3d5;
}
.icon-arrow-left,
.icon-arrow-right,
.icon-arrow-up,
.icon-arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
}
.icon-arrow-up,
.icon-arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.icon-arrow-left,
.icon-arrow-right {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.icon-arrow-left {
  border-right: 5px solid #00b0eb;
}
.icon-arrow-right {
  border-left: 5px solid #00b0eb;
}
.icon-arrow-down {
  border-top: 5px solid #00b0eb;
}
.icon-arrow-up {
  border-bottom: 5px solid #00b0eb;
}
.media-outlined,
img {
  border-radius: 3px;
}
figure {
  width: auto;
  margin: 0;
}
figure img {
  vertical-align: middle;
}
figure figcaption {
  color: #848d90;
  font-size: 12px;
}
.media-outlined {
  display: inline-block;
  padding: 5px;
  border: 1px solid #cdd3d5;
}
@media screen and (max-width: 480px) {
  figure {
    text-align: center;
  }
  figure img + img {
    margin: 5px auto 0;
  }
}
@media screen and (min-width: 481px) {
  figure img:not(:last-child):not(:only-child) {
    margin-right: 5px;
  }
  figure img:not(:only-child) {
    margin-top: 5px;
  }
  figure img + figcaption {
    margin-bottom: 5px;
  }
  figure img + figcaption:not(:only-child) {
    margin-right: 5px;
  }
}
.message {
  padding: 15px;
  position: relative;
  background-color: #e5f9ff;
  color: #00b0eb;
}
.message a {
  font-weight: bold;
}
.message p:last-of-type {
  margin: 0;
}
.message:after {
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255,255,255,0);
  border-width: 5px;
}
.message-dismissable {
  padding-right: 30px;
}
.message-full-width {
  text-align: center;
}
.message-above:after,
.message-below:after {
  left: 50%;
  margin-left: -6px;
}
.message-below:after {
  border-bottom-color: #e5f9ff;
  bottom: 100%;
}
.message-below.message-error:after {
  border-bottom-color: #ffe5ed;
}
.message-below.message-success:after {
  border-bottom-color: #e8f7ee;
}
.message-below.message-alert:after {
  border-bottom-color: #ffedd7;
}
.message-above:after {
  border-top-color: #e5f9ff;
  top: 100%;
}
.message-above.message-error:after {
  border-top-color: #ffe5ed;
}
.message-above.message-success:after {
  border-top-color: #e8f7ee;
}
.message-above.message-alert:after {
  border-top-color: #ffedd7;
}
.message-error {
  background-color: #ffe5ed;
  color: #e60045;
}
.message-error a {
  color: #b80037;
}
.message-error a:hover {
  color: #8a0029;
}
.message-alert {
  background-color: #ffedd7;
  color: #f28a02;
}
.message-alert a {
  color: #c26f02;
}
.message-alert a:hover {
  color: #915301;
}
.message-success {
  background-color: #e8f7ee;
  color: #44bb70;
}
.message-success a {
  color: #369659;
}
.message-success a:hover {
  color: #297043;
}
.close {
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -15px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
}
form .message {
  padding: 10px;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 3px;
  z-index: 20;
  transform: translate(-50%, -50%);
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
.modal.modal-no-sections {
  padding: 30px;
  text-align: center;
}
.modal-title {
  margin: 0;
  float: left;
}
.modal-head,
.modal-body {
  padding: 15px;
}
.modal-head {
  border-bottom: 1px solid #cdd3d5;
}
.modal-footer {
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid #cdd3d5;
}
.modal-footer button,
.modal-footer .button {
  min-width: 120px;
}
.modal-footer button + button,
.modal-footer .button + .button {
  margin-left: 5px;
}
.modal-close {
  float: right;
  color: #cdd3d5;
  line-height: 1.5;
  font-weight: bold;
  font-size: 22px;
}
.modal-close:hover {
  color: #848d90;
}
.has-modal:before {
  content: '';
}
.overlay,
.has-modal:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  box-shadow: inset 0 0 100px rgba(0,0,0,0.7);
  background-color: rgba(0,0,0,0.4);
}
@media screen and (max-width: 480px) {
  .modal {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modal {
    width: 80%;
  }
}
@media screen and (min-width: 769px) {
  .modal {
    width: 50%;
  }
}
.top-nav {
  margin-bottom: 30px;
}
.top-nav ul {
  margin: 0;
}
.top-nav li {
  padding: 15px;
}
.top-nav a {
  display: inline-block;
}
.top-nav label {
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
}
.top-nav .menu-toggle {
  visibility: hidden;
  position: absolute;
  left: -100%;
}
.top-nav .icon-arrow-down {
  position: relative;
  top: -2px;
  margin-left: 5px;
}
.top-nav-light {
  border-bottom: 1px solid #cdd3d5;
}
.top-nav-light label {
  color: #00b0eb;
}
.top-nav-light,
.top-nav-light li {
  background-color: #fff;
}
.top-nav-dark label {
  color: #3a849c;
}
.top-nav-dark a {
  color: #3a849c;
}
.top-nav-dark a:hover,
.top-nav-dark a.active,
.top-nav-dark label {
  color: #7dbbcf;
}
.top-nav-dark,
.top-nav-dark li {
  background-color: #003647;
}
.top-nav-dark .icon-arrow-down {
  border-top-color: #3a849c;
}
.breadcrumbs {
  padding: 10px 0;
}
.breadcrumbs li {
  color: #cdd3d5;
}
.breadcrumbs a {
  padding: 0 10px;
}
.pagination li:not(:last-child) {
  border-right: 1px solid #cdd3d5;
}
.pagination em[class^='icon'],
.pagination em[class*=' icon'] {
  top: 1px;
  position: relative;
}
.pagination .icon-arrow-right {
  border-left: 5px solid #00b0eb;
}
.pagination .icon-arrow-left {
  border-right: 5px solid #00b0eb;
}
.tabs {
  border-bottom: 1px solid #cdd3d5;
}
.tabs a {
  display: block;
}
.side-nav li:not(:last-child) a {
  border-bottom: 1px solid #cdd3d5;
}
.unavailable-item {
  color: #cdd3d5;
}
.unavailable-item:not([href]),
.unavailable-item:not([href]):hover {
  color: #cdd3d5;
  cursor: default;
  pointer-events: none;
}
.current-item {
  color: #505658;
  font-weight: 800;
}
.side-nav a,
.pagination a,
.side-nav li,
.pagination li {
  display: block;
}
.side-nav a:hover:not(.unavailable-item),
.pagination a:hover:not(.unavailable-item),
.side-nav .current-item,
.pagination .current-item {
  background-color: #fbfbfb;
}
.pagination,
.breadcrumbs {
  display: inline-block;
}
.pagination li,
.breadcrumbs li {
  float: left;
}
.breadcrumbs,
.side-nav,
.pagination {
  border-radius: 3px;
  border: 1px solid #cdd3d5;
}
.side-nav a,
.pagination a {
  padding: 10px 15px;
}
.breadcrumbs a,
.pagination a {
  font-size: 12px;
}
@media screen and (max-width: 480px) {
  .tabs a {
    position: relative;
    padding: 10px;
  }
}
@media screen and (min-width: 481px) {
  .tabs a {
    padding: 10px 15px;
  }
  .tabs .current-item {
    position: relative;
    top: 1px;
    border: 1px solid #cdd3d5;
    border-bottom-color: transparent;
    border-radius: 3px 3px 0 0;
    background-color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .top-nav ul {
    display: none;
  }
  .top-nav ul > li {
    padding: 10px;
  }
  .top-nav .icon-arrow-down {
    display: none;
  }
  .top-nav label,
  .top-nav .menu-toggle:checked ~ ul,
  .top-nav .menu-toggle:checked ~ ul > li {
    display: block;
  }
  .top-nav label {
    padding: 15px 15px 15px 0;
    margin-bottom: 0;
    text-align: right;
    cursor: pointer;
  }
  .top-nav .menu-toggle:checked ~ ul .dropdown {
    display: block;
    z-index: 1000;
  }
  .top-nav .menu-toggle:checked ~ ul .dropdown li {
    padding: 5px 15px;
    float: left;
    border: 0;
  }
  .top-nav .menu-toggle:checked ~ ul .dropdown a {
    font-size: 12px;
    font-weight: normal;
  }
  .top-nav-light .menu-toggle:checked ~ ul > li:not(:last-child) {
    border-bottom: 1px solid #cdd3d5;
  }
  .top-nav-dark .menu-toggle:checked ~ ul > li:not(:last-child) {
    border-bottom: 1px solid #1e4552;
  }
}
@media screen and (min-width: 769px) {
  .top-nav label {
    display: none;
  }
  .top-nav .has-dropdown {
    position: relative;
  }
  .top-nav .has-dropdown:hover .dropdown {
    display: block;
    width: 100%;
  }
  .top-nav .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .top-nav .dropdown li {
    display: block;
  }
  .top-nav-light .dropdown {
    border-left: 1px solid #cdd3d5;
    border-right: 1px solid #cdd3d5;
  }
  .top-nav-light .dropdown li {
    border-bottom: 1px solid #cdd3d5;
  }
  .top-nav-dark .dropdown li {
    border-bottom: 1px solid #1e4552;
  }
}
.progress,
.meter {
  border-radius: 3px;
  line-height: 0;
}
.progress.progress-rounded,
.progress-rounded .meter {
  border-radius: 50px;
}
.progress {
  border: 1px solid #cdd3d5;
  background-color: #fdfdfd;
}
.progress .meter {
  display: inline-block;
  background-color: #00b0eb;
  height: 100%;
}
.progress-small {
  height: 15px;
}
.progress-large {
  height: 25px;
}
.progress-success .meter {
  background-color: #44bb70;
}
.progress-warning .meter {
  background-color: #f28a02;
}
.progress-error .meter {
  background-color: #e60045;
}
.table-responsive {
  overflow-x: auto;
}
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
}
td,
th {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #cdd3d5;
}
thead th {
  font-weight: 800;
  color: #505658;
}
tr.table-cell-error {
  background-color: #ffe5ed;
  color: #e60045;
}
tr.table-cell-error a {
  color: #b80037;
}
tr.table-cell-error a:hover {
  color: #8a0029;
}
tr.table-cell-success {
  background-color: #e8f7ee;
  color: #44bb70;
}
tr.table-cell-success a {
  color: #369659;
}
tr.table-cell-success a:hover {
  color: #297043;
}
tr.table-cell-alert {
  background-color: #ffedd7;
  color: #f28a02;
}
tr.table-cell-alert a {
  color: #c26f02;
}
tr.table-cell-alert a:hover {
  color: #915301;
}
.table-striped tr:nth-child(even) {
  color: #848d90;
}
.table-outlined {
  border: 1px solid #cdd3d5;
  border-radius: 3px;
}
.table-outlined tr:last-of-type td {
  border: none;
}
.table-with-hover tr:hover,
.table-striped tr:nth-child(even) {
  background-color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  table td,
  table th {
    padding: 5px 10px;
  }
}
.avatar-radius {
  border-radius: 3px;
}
.avatar-rounded {
  border-radius: 100%;
}
.avatar-small {
  height: 25px;
  width: 25px;
}
.avatar-medium {
  height: 50px;
  width: 50px;
}
.avatar-large {
  height: 100px;
  width: 100px;
}
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: '';
  }
  pre,
  blockquote {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
